import request from '@/utils/request'

export function GetBGImage(params) {
  return request({
    url: 'api/Equipment/GetBGImage',
    method: 'get',
	responseType:'blob',
    params
  })
}
export function GetAgencyEquipJsonData(params) {
  return request({
    url: 'api/Equipment/GetAgencyEquipJsonData',
    method: 'get',
    params
  })
}
export function ElectricEquipmentAmount(params) {
  return request({
    url: 'api/Equipment/ElectricEquipmentAmount',
    method: 'get',
    params
  })
}
export function GetEquipmentInfoInLocation(params) {
  return request({
    url: 'api/Equipment/GetEquipmentAmountInLocation',
    method: 'get',
    params
  })
}
// 登录接口
export function EquipmentLogin(params) {
  return request({
    url: 'api/Equipment/Login',
    method: 'post',
    data:params,
  })
}

// 换肤接口
export function SetSystemUserBgColor(params) {
  return request({
    url: '/api/Equipment/SetSystemUserBgColor',
    method: 'post',
    data:params,
  })
}


//校验用户
export function VerifyUser(params) {
  return request({
    url: '/api/System/VerifyUser',
    method: 'post',
    data:params
  })
}
//注册用户
export function RegisterUser(params) {
  return request({
    url: '/api/System/RegisterUser',
    method: 'post',
    data:params
  })
}