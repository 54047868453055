<template>
  <div class="home">
	 <el-container :style="{height: 100 + 'vh' }">
		<div :class="check ? 'broadside' : 'broadside2'">
			<el-menu 
			background-color="transparent"
			active-text-color="#1a6a42"
			class="el-menu-vertical-demo"
			:class="check ? 'elmenu1' : 'elmenu2'"
			style="width:240px;"
			:collapse="isCollapse"
			unique-opened
			:router="true"
			:collapse-transition="false"
			 :default-active="onRoutes"
			>
			<div class="biaoti" v-if="check">
			  <span style="font-size: 18px;">
			    <img v-if="theme=='white'" src="@/assets/logo/logo.png" alt="" />
				<img v-else src="@/assets/logo/logo2.png" alt="" />
			  </span>
			</div>
			<div class="biaoti2" v-else>
			  <span style="font-size: 18px;">
			    <img src="@/assets/logo/logo-bars.png" alt="智慧机联网管理平台" />
			  </span>
			</div>
				<div v-for="item in menu" :key="item.MenuId">
					<template v-if="item.Children.length">
					  <el-submenu :index="item.MenuRoute">
						<template slot="title" >
						 <!-- <i :class="item.icon" style="color: #094"></i> -->
						 <img class="deviceImage" :src="require('@/assets/icons/'+ (item.Icon) + '.png')">
						 <span slot="title">{{item.MenuName}}</span>
						</template>
						<el-menu-item v-for="subItem in item.Children" :key="subItem.MenuId" :index="subItem.MenuRoute">
						 <span slot="title" class="labelStyle">{{ subItem.MenuName }}</span>
						</el-menu-item>
					  </el-submenu>
					</template>
					<template v-else>
					  <el-menu-item :index="item.MenuRoute">
						<!-- <i :class="item.icon" style="color: #094"></i> -->
						<img height="100%" v-if="item.Icon" class="deviceImage" :src="require('@/assets/icons/'+ (item.Icon) + '.png')">
						<span slot="title">{{ item.MenuName }}</span>
					  </el-menu-item>
					</template>
				  </div>
            <!---->			
			</el-menu>
		</div>
		<el-container>
			<el-header>
				<div class="icons" @click="toggleCollapse">
					<i class="el-icon-s-unfold" v-if="isCollapse"></i>
					<i class="el-icon-s-fold"></i>
				</div>
				<!-- <div class="mbx">
					<el-breadcrumb separator="/">
					  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
					  <el-breadcrumb-item v-for="(item, indexs) in breadlist" :key="'info'+indexs">
						  {{item.pathname}}
					  </el-breadcrumb-item>
					  <el-breadcrumb-item v-for="(item, index) in breadlist" :key="index" :to="item.to">
					     {{item.name}}
					  </el-breadcrumb-item>
					</el-breadcrumb>
				</div> -->
				<div class="headeright">
					<span style="padding: 18px;">
						<el-tooltip :effect="theme == 'white' ? 'light':'dark'" content="切换皮肤" placement="top-start">
							<img @click="toggoleTheme" v-if="theme=='white'" src="../../static/icon/peeling.png"/>
							<img @click="toggoleTheme" v-else src="../../static/icon/peeling2.png"/>
						</el-tooltip>
						<span class="logout" @click="logout">{{user}}</span>
					</span>
					<!-- <el-dropdown @command="setDialogInfo">
						<span class="el-dropdown-link">
						<span class="titles">用户设置</span>
						<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="info">个人信息</el-dropdown-item>
							<el-dropdown-item command="logout">退出登录</el-dropdown-item>
					   </el-dropdown-menu>
					</el-dropdown> -->
				</div>
			</el-header>
			<el-main style="overflow: hidden;">
				<div class="maincontent">
					<keep-alive>
					  <router-view v-if="$route.meta.keepAlive"></router-view>
					</keep-alive>
					<router-view v-if="!$route.meta.keepAlive"></router-view>
				</div>
			</el-main>
		</el-container>
	</el-container>
  </div>
</template>
<script>
import {mapGetters,mapMutations} from 'vuex'
import Tags from "../components/tags/Tags"
import { encryptDes, decryptDes } from '@/common/js/des.js'
import {
	SetSystemUserBgColor,
} from "@/api/user.js"; //页面接口
export default {
	name: 'Home',
	components:{Tags},
	data() {
		return {
		  isCollapse: false,
		  user:"",
		  check:true,
		  menu:[],
		  activerouter:'',
		  breadlist: [],
		  theme: sessionStorage.getItem("BgColorType"),
		};
	},
	 watch: {
	 // 监听路由
		 $route(val) {
			// 调用获取路由数组方法
			this.isCollapse = false
			if(this.isCollapse==false){
				this.check=true;
				this.setExpansion(true)
			}
		 }
	 },
	computed:{
	  ...mapGetters(['expansion']),
	  onRoutes() {
	    const route = this.$route
	    const { meta, path } = route
	    // 可以在路由配置文件中设置自定义的路由路径到meta.activeMenu属性中，来控制菜单自定义高亮显示
	    // meta中 有activeMenu 字段的子页面，都会显示高亮
	    if (meta.activeMenu) {
	      return meta.activeMenu
	    }
	    return path
	  }
	},
	mounted() {
		//初始换肤
		document.documentElement.setAttribute('data-theme', this.theme );
		// 菜单
		let menuList = JSON.parse(sessionStorage.getItem("MenuTree"))
		if (menuList) {
			this.menu = menuList
		}
		// 用户信息
		let useNm = sessionStorage.getItem("User");
		let user = ""
		if (useNm) {
			user = decryptDes(useNm,'engo1234');
		}
		this.user=user;
	},
	methods: {
		...mapMutations(['setExpansion']),
		toggleCollapse() {
		  this.isCollapse = !this.isCollapse
		  if(this.isCollapse==false){
			  this.check=true;
			  this.setExpansion(true)
		  }else{
			  this.check=false;
			  this.setExpansion(false)
		  }
		},
		  logout(){
			 this.$confirm('确定退出登录吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'success'
			  }).then(() => { 
				//点击确定的操作(调用接口)
				window.sessionStorage.clear();
				this.$router.push('/login')
			  }).catch(() => {
				return;
			  });
		  },
		toggoleTheme() {
		  this.theme = this.theme === 'white' ? 'black' : 'white'
			SetSystemUserBgColor({
				userNo: sessionStorage.getItem("UserNo"),
				bgColorType: this.theme
			})
			.then((res) => {
				if(res.Code==-1) {
					this.$notify.info({
						title:'消息',
						message: '换肤接口操作失败'
					});
					return;
				}
			})
			.catch((err) => {

			})
		// 通过setAttribute设置data-theme主题进行切换
		  this.$store.commit('upDate',this.theme);
		  //存颜色
			sessionStorage.setItem("BgColorType", this.theme);
		  document.documentElement.setAttribute('data-theme', this.theme );
		}
	}
}
</script>
<style scoped="scoped" lang="scss">
@import '@/assets/mixin.scss';
.broadside{
	width:245px;
	margin-bottom: 100px;
	height: calc(100vh);
	@include background_color("background9_color_white");
}
.broadside2{
	width:64px;
	margin-bottom: 100px;
	height: calc(100vh);
	@include background_color("background9_color_white");
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  height: calc(100vh);
  overflow-y: auto;
}
.logout{
	margin-left: 20px;
}
.logout:hover{
	@include font_color("font_color_hover_white");
}
@media only screen and (max-aspect-ratio: 1920/920 ) {
.elmenu1{
	width: 240px!important;
	margin-top: 68px;
	height: calc(100vh - 60px);
}
.elmenu2{
	width: 64px!important;
	margin-top: 68px;
	height: calc(100vh - 60px);
}
.mbx{
	padding: 20px;
	font-size: 20px;
}
.headeright{
	font-size: 18px;
	position: absolute;
	cursor: pointer;
	right: 10px;
}
.headeright img{
	display: inline-block;
	width: 19px;
	height: 17px;
	margin-right: 5px;
	margin-top: -1px;
	vertical-align: middle;
}
.skin{
	font-size: 15px;
	@include font_color("font2_color_white");
	padding-right: 20px;
}
.maincontent{
	overflow-y: hidden;
	height: 98%;
	padding: 15px;
}
::-webkit-scrollbar{
	display:none;
}
.biaoti {
  @include background_color("background9_color_white");
  width: 240px;
  height: 65px;
  line-height: 90px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  @include box_shadow("box_shadow");
  // box-shadow: 0px 5px 2px -2px #f5f5f5; 
}
.biaoti img{
	display: inline-block;
	width: 200px;
	height: 40px;
}
.biaoti2 {
  @include background_color("background9_color_white");
  width: 64px;
  height: 65px;
  line-height: 75px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  // box-shadow: 0px 5px 2px -2px #f5f5f5; 
  @include box_shadow("box_shadow");
}
.biaoti2 img{
	display: inline-block;
	width: 45px;
}
.el-header{
	@include background_color("background9_color_white");
	@include font_color("font_color_white");
	height: 65px!important;
	line-height: 65px!important;
	display: flex;
	float: left;
	padding: 0 !important;
	// box-shadow: 0px 5px 2px -2px #f5f5f5; 
	@include box_shadow("box_shadow");
}
.el-header i {
	font-size: 25px;
}
.el-main {
	@include background_color("background2_color_white");
	// background-color: #f9f9f9;
	padding: 20px 4px;
}
.icons {
	width: 25px;
	height: 65px;
	padding: 0 20px;
	cursor: pointer;
	text-align: center
}
.deviceImage{
	display: inline-block;
	width: 20px;
	height: 20px;
	vertical-align: middle;
	margin-right: 10px;
}
}
@media only screen and (min-aspect-ratio: 1920/920 ) {
	.elmenu1{
		width: 240px!important;
		margin-top: 6.8vh;
		height: calc(100vh - 6vh);
	}
	.elmenu2{
		width: 64px!important;
		margin-top: 6.8vh;
		height: calc(100vh - 6vh);
	}
	.mbx{
		padding: 2vh;
		font-size: 20px;
	}
	.headeright{
		font-size: 18px;
		position: absolute;
		right: 10px;
		cursor: pointer;
	}
	.headeright img{
		display: inline-block;
		width: 19px;
		height: 1.7vh;
		margin-right: 5px;
		margin-top: -1px;
		vertical-align: middle;
	}
	.skin{
		font-size: 15px;
		@include font_color("font2_color_white");
		padding-right: 20px;
	}
	.maincontent{
		overflow-y: hidden;
		height: 98%;
		padding: 1.5vh;
	}
	::-webkit-scrollbar{
		display:none;
	}
	.biaoti {
	  @include background_color("background9_color_white");
	  width: 240px;
	  height: 6.5vh;
	  line-height: 9vh;
	  text-align: center;
	  position: fixed;
	  top: 0;
	  left: 0;
	  z-index: 9;
	  // box-shadow: 0px 5px 2px -2px #f5f5f5; 
	  @include box_shadow("box_shadow");
	}
	.biaoti img{
		display: inline-block;
		width: 200px;
		height: 4vh;
	}
	.biaoti2 {
	  @include background_color("background9_color_white");
	  width: 64px;
	  height: 6.5vh;
	  line-height: 7.5vh;
	  text-align: center;
	  position: fixed;
	  top: 0;
	  left: 0;
	  z-index: 9;
	  // box-shadow: 0px 5px 2px -2px #f5f5f5; 
	  @include box_shadow("box_shadow");
	}
	.biaoti2 img{
		display: inline-block;
		width: 45px;
	}
	.el-header{
		@include background_color("background9_color_white");
		@include font_color("font_color_white");
		height: 6.5vh!important;
		line-height: 6.5vh!important;
		display: flex;
		float: left;
		padding: 0 !important;
		// box-shadow: 0px 5px 2px -2px #f5f5f5; 
		@include box_shadow("box_shadow");
	}
	.el-header i {
		font-size: 25px;
	}
	.el-main {
		@include background_color("background2_color_white");
		// background-color: #f9f9f9;
		padding: 2vh 4px;
	}
	.icons {
		width: 25px;
		height: 6.5vh;
		padding: 0 20px;
		cursor: pointer;
		text-align: center
	}
	.deviceImage{
		display: inline-block;
		width: 20px;
		height: 20px;
		vertical-align: middle;
		margin-right: 10px;
	}
}
</style>
